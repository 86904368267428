.modalBackground {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1000;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(10, 10, 10, 0.54);
}
.MuiAutocomplete-root{
  width: 100%;
}
.modalContainer {
  width: 800px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.demo-editor {
  height: 100px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 34px;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 95px;
  height: 45px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
