.messageTypeForm_container,
.messageType_container {
  height: calc(100vh - 113px);
  padding: 1px 3px 10px 1px;
  overflow: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .messageType_container {
    height: calc(100vh - 10.5rem);
  }
  .messageTypeForm_container {
    height: calc(100vh - 9rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .messageType_container {
    height: calc(100vh - 10.5rem);
  }
}
