.maindiv {
  height: 100%;
  width: 100%;
  position: fixed;
}
.header {
  width: 100%;
  height: 50px;
}
.body {
  width: 100%;
}
@media screen and (width: 1000px), screen and (width: 700px) {
  .ex {
    padding-right: 70px;
  }
}
.barre {
  width: 100%;
  height: 50px;
  position: sticky;
  top: 50%;
  background-color: #117aa7;
}
.table {
  width: 100%;
  height: calc(50% - 25px);
  overflow-y: scroll;
}
.ContactInfo {
  width: 100%;
  height: calc(50% - 25px);
}

.contentWithHead {
  height: calc(100vh - 229px);
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contentWithHead {
    height: calc(100vh - 19rem);
    overflow-y: auto;
  }
}
