.MuiStepIcon-root.MuiStepIcon-completed {
  color: rgb(112, 236, 112) !important;
}

.candidatedit_container {
  overflow-y: auto;
  height: calc(100vh - 160px);
  padding: 1px 3px 10px 1px;
  overflow-x: hidden;
  scroll-padding-top: 0px;
  scroll-behavior: smooth;
}
.candidatedit_header {
  box-sizing: border-box;
  padding-bottom: 15px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .candidatedit_header {
    padding: 3px 0px 15px 3px;
  }
  .candidatedit_container {
    height: calc(100vh - 12rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .candidatedit_container {
    height: calc(100vh - 12rem);
  }
  .candidatedit_header {
    padding: 3px 0px 15px 3px;
  }
}
