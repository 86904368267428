@font-face {
    font-family: myFont;
    src: url(../../font/AlegreyaSansSC-Italic.ttf);
  }

  @keyframes mymove {
    100% {transform: rotate(180deg);}
  }

  #dashboard-inner {
    padding-top: 30px;
  }