.title {
  font-size: 16px;
}
.close--icon {
  position: absolute;
  top: 5px;
  right: 10px;
  color: red;
  opacity: 0.6;
  font-size: 23px;
  cursor: pointer;
}
.close--icon:hover {
  opacity: 1;
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .title {
    font-size: 13px;
  }
}
