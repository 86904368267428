#headercontainer {
  text-align: center;
  color: black;
  font-family: gothic;
  font-size: 2vw;
  position: relative;
  left: -7vw;
}
#lab {
  font-size: 1vw;
}
#ajouter {
  top: 3vw;
  right: 1vw;
  position: relative;
}
#icondel {
  background-color: red;
  position: relative;
  left: 0.75vw;
}
#table {
  width: 70vw;
}
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.missions_container {
  height: calc(100vh - 235px);
  padding: 1px 3px 10px 1px;
  overflow-x: hidden;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .mission_content {
    height: calc(100vh - 175px);
    overflow-y: auto;
  }
  .missions_container {
    height: calc(100vh - 135px);
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .missions_container {
    height: calc(100vh - 275px);
  }
}
