.candidatTask_container {
  height: calc(100vh - 229px);
  overflow-y: auto;
  /* padding: 1px 3px 10px 1px; */
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .candidatTask_container {
    height: calc(100vh - 269px);
  }
  h2 {
    font-size: 20px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  h2 {
    font-size: 20px !important;
  }
  .task_content {
    height: calc(100vh - 155px);
    overflow-y: auto;
  }
  .candidatTask_container {
    height: calc(100vh - 115px);
    overflow: hidden;
    /* padding: 1px 3px 0px 1px; */
  }
}
