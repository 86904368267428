@media (max-width: 575.98px) {
  .list_header {
    display: block;
  }
}
.list_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.datatable_info_stack {
  display: inline-flex;
  font-size: 0.5em;
  padding-left: 0.35em;
  display: inline-flex;
}
.dataTables_filter {
  text-align: right;
}
.main_content_iner .container-fluid {
  padding-left: 0rem;
}
.main_content_iner .container-fluid .animated {
  margin-top: 1.5rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.dataTables_filter label {
  font-weight: 400;
  white-space: nowrap;
  text-align: left;
}
.dataTables_filter input {
  display: inline-block;
  width: auto;
  border-radius: 25px;
}
