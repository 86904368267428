.MuiButton-label .MuiButton-iconSizeMedium {
  margin: 0px !important;
}

.MuiButton-startIcon svg {
  font-size: 22px !important;
}

.MuiButton-label {
  width: fit-content !important;
}
.MuiTypography-root .MuiButtonBase-root {
  color: #117aa7 !important;
  border-radius: 65% !important;
  /* height: 45px !important; */
}

.MuiFormControl-fullWidth {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
em {
  font-style: initial !important;
}
