.selection_container {
  height: calc(100vh - 113px);
  padding: 1px 3px 10px 1px;
  overflow-x: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .selection_container {
    overflow-y: auto;
    height: calc(100vh - 10.5rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .selection_container {
    height: calc(100vh - 10.5rem);
  }
  .candidatedit_header {
    padding: 3px 0px 15px 3px;
  }
}
