@font-face {
  font-family: myFirstFont;
  src: url(../../../font/AlegreyaSansSC-Italic.ttf);
}
.header_iner .header_right .header_notification_warp {
  margin-right: 20px;
  margin-left: 40px;
} 
.iconposistion {
  align-items: flex-start !important;
  width: auto;
  font-family: myFirstFont;
  font-size: 20px;
  justify-content: right;
}
@media only screen and (max-width: 700px) {
  .iconposistion {
    font-size: 15px ;
  }
}
.profile_info {
  position: relative;
}
.profile_info img {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile_info .profile_info_iner {
  position: absolute;
  right: 0;
  background-color: #2e4765;
  text-align: right;
  width: 230px;
  padding: 30px 30px 20px;
  opacity: 0;
  display: none;
  top: 77px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 405;
}
.profile_info .profile_info_iner::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  top: -7px;
  right: 10px;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #2e4765 transparent;
}

.profile_info .profile_info_iner h5 {
  font-size: 12px;
  color: #fff;
  text-align: left;

}

.profile_info .profile_info_details {
  margin-top: 20px;
  border-top: 1px solid #79838b;
  padding-top: 10px;
  text-align: left;
}

.profile_info .profile_info_iner p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0px;
  color: #828bb2;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: left;
}
