.reporting_container {
  height: calc(100vh - 235px);
  padding: 1px 3px 10px 1px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .reporting_content {
    height: calc(100vh - 175px);
    overflow-y: auto;
  }
  .reporting_container {
    height: calc(100vh - 135px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .reporting_container {
    height: calc(100vh - 385px);
  }
}
