/* dashboard header */
.header_iner {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 2px;
  position: relative;
  border-radius: 20px;
  justify-content: flex-end;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}

@media (max-width: 575.98px) {
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}
.sidebar_icon {
  position: absolute;
  top: 53%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-70%);
  font-size: 25px;
  cursor: pointer;
}
.sidebar_icon i {
  font-size: 20px;
}
.ti-menu:before {
  content: "\e68e";
}

@media only screen and (min-width: 1200px) {
  .header_iner {
    margin-bottom: 1em;
    justify-content: flex-end;
  }
  .sidebar_icon {
    display: none;
  }
}
.headernav {
  position: fixed;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner {
    margin: 0;
  }
}

@media (max-width: 1150px) {
  .header_iner {
    padding: 20px;
    margin: 0;
    justify-content: flex-end;
  }
}
@media (max-width: 1150px) {
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner {
    justify-content: flex-end !important;
  }
}

@media (max-width: 575.98px) {
  .header_iner {
    justify-content: flex-end !important;
  }
}
.align-items-center{
  height: 60px;
}