.ql-container.ql-snow {
  height: auto;
}
.ql-editor {
  height: 250px;
  overflow-y: auto;
}

.ql-container {
  box-sizing: border-box !important;
  font-family: inherit !important;
  font-size: 16px !important;
}
