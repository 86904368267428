#headercontainer {
  text-align: center;
  color: black;
  font-family: gothic;
  font-size: 2vw;
  position: relative;
  left: -7vw;
}
#lab {
  font-size: 1vw;
}
#ajouter {
  top: 3vw;
  right: 1vw;
  position: relative;
}
#icondel {
  background-color: red;
}
#table {
  width: 70vw;
}

.user_container {
  overflow-y: auto;
  height: calc(100vh - 113px);
  padding: 1px 3px 10px 1px;
  overflow-x: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .user_container {
    height: calc(100vh - 10.5rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .user_container {
    height: calc(100vh - 10.5rem);
  }
}
